import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Quill from 'quill';

//  Change Block <p> tag to <div> to prevent unnecessary spacing
var Block = Quill.import('blots/block');

Block.tagName = 'DIV';
Quill.register(Block, true);

export function initQuill(element) {

    const $element = $(element);

    if ($element.hasClass('is-initialized')) return;

    const config = JSON.parse(element.dataset.config || '{}');

    const quill = new Quill(element, $.extend(true, {
        modules: {
            toolbar: [
                ['bold', 'italic'],        // toggled buttons
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link'],
            ]
        },
        placeholder: $element.attr('data-placeholder'),
        theme: 'snow'
    }, config));

    $element.closest('form').on('submit', function () {

        const $hiddenInput = $element.closest('.form__item').find('input[type="hidden"]');

        $hiddenInput.val(quill.container.firstChild.innerHTML);
    });

    $element.addClass('is-initialized');
}

$('.js-quill').each(function () {

    initQuill(this);
});
