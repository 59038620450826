
import 'parsleyjs';

export function resetForm($form) {

    if (!$form.length) return;

    const formElements = $form[0].elements;

    $form[0].reset();

    //  Clear collections
    $form.find('[id^="form-collection-"] [data-id="0"]').siblings().remove();

    //  Clear Quill editors
    $form.find('.ql-editor').html('');

    //  Clear selectize
    $form.find('select.selectized').each(function () {
        this.selectize.clear();
    });

    //  Clear file uploads
    $form.find('.js-upload-count').text('0');
    $form.find('.js-upload-list').empty();

    $.each(formElements, (i, formElement) => {

        if (!formElement.type) return;

        if (formElement.name === '_token') return;

        const fieldType = formElement.type.toLowerCase();

        switch (fieldType) {

            case 'text':
            case 'password':
            case 'textarea':
            case 'hidden':

                formElement.value = '';
                break;

            case 'radio':
            case 'checkbox':

                if (formElement.checked) {
                    formElement.checked = false;
                }

                break;

            case 'select-one':
            case 'select-multi':

                formElement.selectedIndex = -1;
                break;

            default:
                break;
        }
    });
}

export function submitForm($form) {

    const $button = $form.find('button[type="submit"]');
    let $methodInput = $form.find('[name="_method"]');

    const formMethod = $form.attr('method');
    const buttonMethod = $button ? $button.attr('data-method') : null;

    //  Spoof the form method with a hidden field

    if (!$methodInput.length) {

        $methodInput = $(`<input type="hidden" name="_method" value="${formMethod}">`);
        $methodInput.appendTo($form);
    }

    if (buttonMethod) {

        $methodInput.attr('value', buttonMethod).val(buttonMethod);
    } else {

        $methodInput.attr('value', formMethod).val(formMethod);
    }

    //  Prevent multiple submissions
    if ($form.data('is-submitted')) return false;

    if ($form.parsley().isValid()) {
        $form.data('is-submitted', true).addClass('is-submitted');
    }
}
