import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import tippy from 'tippy.js';

$('[data-tooltip]').each(function () {
    initTooltip(this);
});

export function initTooltip(element) {

    const content = document.createElement('div');

    content.innerHTML = element.dataset.tooltip;

    if (typeof element._tippy === 'undefined') {

        tippy(element, {
            content
        });
    }
}
